'use strict';

exports.__esModule = true;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactLifecyclesCompat = require('react-lifecycles-compat');

var _MonthTable = require('./MonthTable');

var _MonthTable2 = _interopRequireDefault(_MonthTable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function goYear(direction) {
  this.props.changeYear(direction);
}

function noop() {}

var MonthPanel = function (_React$Component) {
  (0, _inherits3['default'])(MonthPanel, _React$Component);

  function MonthPanel(props) {
    (0, _classCallCheck3['default'])(this, MonthPanel);

    var _this = (0, _possibleConstructorReturn3['default'])(this, _React$Component.call(this, props));

    _this.setAndSelectValue = function (value) {
      _this.setValue(value);
      _this.props.onSelect(value);
    };

    _this.setValue = function (value) {
      if ('value' in _this.props) {
        _this.setState({
          value: value
        });
      }
    };

    _this.nextYear = goYear.bind(_this, 1);
    _this.previousYear = goYear.bind(_this, -1);
    _this.prefixCls = props.rootPrefixCls + '-month-panel';

    _this.state = {
      value: props.value || props.defaultValue
    };
    return _this;
  }

  MonthPanel.getDerivedStateFromProps = function getDerivedStateFromProps(props) {
    var newState = {};

    if ('value' in props) {
      newState = {
        value: props.value
      };
    }

    return newState;
  };

  MonthPanel.prototype.render = function render() {
    var props = this.props;
    var value = this.state.value;
    var locale = props.locale,
        cellRender = props.cellRender,
        contentRender = props.contentRender,
        renderFooter = props.renderFooter;

    var year = value.year();
    var prefixCls = this.prefixCls;

    var footer = renderFooter && renderFooter('month');

    return _react2['default'].createElement(
      'div',
      { className: prefixCls, style: props.style },
      _react2['default'].createElement(
        'div',
        null,
        _react2['default'].createElement(
          'div',
          { className: prefixCls + '-header' },
          _react2['default'].createElement('a', {
            className: prefixCls + '-prev-year-btn',
            role: 'button',
            onClick: this.previousYear,
            title: locale.previousYear
          }),
          _react2['default'].createElement(
            'a',
            {
              className: prefixCls + '-year-select',
              role: 'button',
              onClick: props.onYearPanelShow,
              title: locale.yearSelect
            },
            _react2['default'].createElement(
              'span',
              { className: prefixCls + '-year-select-content' },
              year
            ),
            _react2['default'].createElement(
              'span',
              { className: prefixCls + '-year-select-arrow' },
              'x'
            )
          ),
          _react2['default'].createElement('a', {
            className: prefixCls + '-next-year-btn',
            role: 'button',
            onClick: this.nextYear,
            title: locale.nextYear
          })
        ),
        _react2['default'].createElement(
          'div',
          { className: prefixCls + '-body' },
          _react2['default'].createElement(_MonthTable2['default'], {
            disabledDate: props.disabledDate,
            onSelect: this.setAndSelectValue,
            locale: locale,
            value: value,
            cellRender: cellRender,
            contentRender: contentRender,
            prefixCls: prefixCls
          })
        ),
        footer && _react2['default'].createElement(
          'div',
          { className: prefixCls + '-footer' },
          footer
        )
      )
    );
  };

  return MonthPanel;
}(_react2['default'].Component);

MonthPanel.propTypes = {
  onChange: _propTypes2['default'].func,
  disabledDate: _propTypes2['default'].func,
  onSelect: _propTypes2['default'].func,
  renderFooter: _propTypes2['default'].func,
  rootPrefixCls: _propTypes2['default'].string,
  value: _propTypes2['default'].object,
  defaultValue: _propTypes2['default'].object
};
MonthPanel.defaultProps = {
  onChange: noop,
  onSelect: noop
};


(0, _reactLifecyclesCompat.polyfill)(MonthPanel);

exports['default'] = MonthPanel;
module.exports = exports['default'];