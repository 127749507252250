'use strict';

exports.__esModule = true;
exports.commonMixinWrapper = exports.defaultProp = exports.propType = undefined;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _en_US = require('../locale/en_US');

var _en_US2 = _interopRequireDefault(_en_US);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function noop() {}

var propType = exports.propType = {
  className: _propTypes2['default'].string,
  locale: _propTypes2['default'].object,
  style: _propTypes2['default'].object,
  visible: _propTypes2['default'].bool,
  onSelect: _propTypes2['default'].func,
  prefixCls: _propTypes2['default'].string,
  onChange: _propTypes2['default'].func,
  onOk: _propTypes2['default'].func
};

var defaultProp = exports.defaultProp = {
  locale: _en_US2['default'],
  style: {},
  visible: true,
  prefixCls: 'rc-calendar',
  className: '',
  onSelect: noop,
  onChange: noop,
  onClear: noop,
  renderFooter: function renderFooter() {
    return null;
  },
  renderSidebar: function renderSidebar() {
    return null;
  }
};

var commonMixinWrapper = exports.commonMixinWrapper = function commonMixinWrapper(ComposeComponent) {
  var _class, _temp2;

  return _temp2 = _class = function (_ComposeComponent) {
    (0, _inherits3['default'])(_class, _ComposeComponent);

    function _class() {
      var _temp, _this, _ret;

      (0, _classCallCheck3['default'])(this, _class);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = (0, _possibleConstructorReturn3['default'])(this, _ComposeComponent.call.apply(_ComposeComponent, [this].concat(args))), _this), _this.getFormat = function () {
        var format = _this.props.format;
        var _this$props = _this.props,
            locale = _this$props.locale,
            timePicker = _this$props.timePicker;

        if (!format) {
          if (timePicker) {
            format = locale.dateTimeFormat;
          } else {
            format = locale.dateFormat;
          }
        }
        return format;
      }, _this.focus = function () {
        if (_this.focusElement) {
          _this.focusElement.focus();
        } else if (_this.rootInstance) {
          _this.rootInstance.focus();
        }
      }, _this.saveFocusElement = function (focusElement) {
        _this.focusElement = focusElement;
      }, _this.saveRoot = function (root) {
        _this.rootInstance = root;
      }, _temp), (0, _possibleConstructorReturn3['default'])(_this, _ret);
    }

    _class.prototype.shouldComponentUpdate = function shouldComponentUpdate(nextProps) {
      return this.props.visible || nextProps.visible;
    };

    return _class;
  }(ComposeComponent), _class.displayName = 'CommonMixinWrapper', _class.defaultProps = ComposeComponent.defaultProps, _class.getDerivedStateFromProps = ComposeComponent.getDerivedStateFromProps, _temp2;
};