'use strict';

exports.__esModule = true;

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _KeyCode = require('rc-util/lib/KeyCode');

var _KeyCode2 = _interopRequireDefault(_KeyCode);

var _reactLifecyclesCompat = require('react-lifecycles-compat');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _util = require('../util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var cachedSelectionStart = void 0;
var cachedSelectionEnd = void 0;
var dateInputInstance = void 0;

var DateInput = function (_React$Component) {
  (0, _inherits3['default'])(DateInput, _React$Component);

  function DateInput(props) {
    (0, _classCallCheck3['default'])(this, DateInput);

    var _this = (0, _possibleConstructorReturn3['default'])(this, _React$Component.call(this, props));

    _initialiseProps.call(_this);

    var selectedValue = props.selectedValue;

    _this.state = {
      str: (0, _util.formatDate)(selectedValue, _this.props.format),
      invalid: false,
      hasFocus: false
    };
    return _this;
  }

  DateInput.prototype.componentDidUpdate = function componentDidUpdate() {
    if (dateInputInstance && this.state.hasFocus && !this.state.invalid && !(cachedSelectionStart === 0 && cachedSelectionEnd === 0)) {
      dateInputInstance.setSelectionRange(cachedSelectionStart, cachedSelectionEnd);
    }
  };

  DateInput.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, state) {
    var newState = {};

    if (dateInputInstance) {
      cachedSelectionStart = dateInputInstance.selectionStart;
      cachedSelectionEnd = dateInputInstance.selectionEnd;
    }
    // when popup show, click body will call this, bug!
    var selectedValue = nextProps.selectedValue;
    if (!state.hasFocus) {
      newState = {
        str: (0, _util.formatDate)(selectedValue, nextProps.format),
        invalid: false
      };
    }

    return newState;
  };

  DateInput.getInstance = function getInstance() {
    return dateInputInstance;
  };

  DateInput.prototype.render = function render() {
    var props = this.props;
    var _state = this.state,
        invalid = _state.invalid,
        str = _state.str;
    var locale = props.locale,
        prefixCls = props.prefixCls,
        placeholder = props.placeholder,
        clearIcon = props.clearIcon,
        inputMode = props.inputMode;

    var invalidClass = invalid ? prefixCls + '-input-invalid' : '';
    return _react2['default'].createElement(
      'div',
      { className: prefixCls + '-input-wrap' },
      _react2['default'].createElement(
        'div',
        { className: prefixCls + '-date-input-wrap' },
        _react2['default'].createElement('input', {
          ref: this.saveDateInput,
          className: prefixCls + '-input ' + invalidClass,
          value: str,
          disabled: props.disabled,
          placeholder: placeholder,
          onChange: this.onInputChange,
          onKeyDown: this.onKeyDown,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          inputMode: inputMode
        })
      ),
      props.showClear ? _react2['default'].createElement(
        'a',
        {
          role: 'button',
          title: locale.clear,
          onClick: this.onClear
        },
        clearIcon || _react2['default'].createElement('span', { className: prefixCls + '-clear-btn' })
      ) : null
    );
  };

  return DateInput;
}(_react2['default'].Component);

DateInput.propTypes = {
  prefixCls: _propTypes2['default'].string,
  timePicker: _propTypes2['default'].object,
  value: _propTypes2['default'].object,
  disabledTime: _propTypes2['default'].any,
  format: _propTypes2['default'].oneOfType([_propTypes2['default'].string, _propTypes2['default'].arrayOf(_propTypes2['default'].string)]),
  locale: _propTypes2['default'].object,
  disabledDate: _propTypes2['default'].func,
  onChange: _propTypes2['default'].func,
  onClear: _propTypes2['default'].func,
  placeholder: _propTypes2['default'].string,
  onSelect: _propTypes2['default'].func,
  selectedValue: _propTypes2['default'].object,
  clearIcon: _propTypes2['default'].node,
  inputMode: _propTypes2['default'].string
};

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  this.onClear = function () {
    _this2.setState({
      str: ''
    });
    _this2.props.onClear(null);
  };

  this.onInputChange = function (event) {
    var str = event.target.value;
    var _props = _this2.props,
        disabledDate = _props.disabledDate,
        format = _props.format,
        onChange = _props.onChange,
        selectedValue = _props.selectedValue;

    // 没有内容，合法并直接退出

    if (!str) {
      onChange(null);
      _this2.setState({
        invalid: false,
        str: str
      });
      return;
    }

    // 不合法直接退出
    var parsed = (0, _moment2['default'])(str, format, true);
    if (!parsed.isValid()) {
      _this2.setState({
        invalid: true,
        str: str
      });
      return;
    }

    var value = _this2.props.value.clone();
    value.year(parsed.year()).month(parsed.month()).date(parsed.date()).hour(parsed.hour()).minute(parsed.minute()).second(parsed.second());

    if (!value || disabledDate && disabledDate(value)) {
      _this2.setState({
        invalid: true,
        str: str
      });
      return;
    }

    if (selectedValue !== value || selectedValue && value && !selectedValue.isSame(value)) {
      _this2.setState({
        invalid: false,
        str: str
      });
      onChange(value);
    }
  };

  this.onFocus = function () {
    _this2.setState({ hasFocus: true });
  };

  this.onBlur = function () {
    _this2.setState(function (prevState, prevProps) {
      return {
        hasFocus: false,
        str: (0, _util.formatDate)(prevProps.value, prevProps.format)
      };
    });
  };

  this.onKeyDown = function (event) {
    var keyCode = event.keyCode;
    var _props2 = _this2.props,
        onSelect = _props2.onSelect,
        value = _props2.value,
        disabledDate = _props2.disabledDate;

    if (keyCode === _KeyCode2['default'].ENTER && onSelect) {
      var validateDate = !disabledDate || !disabledDate(value);
      if (validateDate) {
        onSelect(value.clone());
      }
      event.preventDefault();
    }
  };

  this.getRootDOMNode = function () {
    return _reactDom2['default'].findDOMNode(_this2);
  };

  this.focus = function () {
    if (dateInputInstance) {
      dateInputInstance.focus();
    }
  };

  this.saveDateInput = function (dateInput) {
    dateInputInstance = dateInput;
  };
};

(0, _reactLifecyclesCompat.polyfill)(DateInput);

exports['default'] = DateInput;
module.exports = exports['default'];