'use strict';

exports.__esModule = true;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _KeyCode = require('rc-util/lib/KeyCode');

var _KeyCode2 = _interopRequireDefault(_KeyCode);

var _reactLifecyclesCompat = require('react-lifecycles-compat');

var _CalendarHeader = require('./calendar/CalendarHeader');

var _CalendarHeader2 = _interopRequireDefault(_CalendarHeader);

var _CalendarFooter = require('./calendar/CalendarFooter');

var _CalendarFooter2 = _interopRequireDefault(_CalendarFooter);

var _CalendarMixin = require('./mixin/CalendarMixin');

var _CommonMixin = require('./mixin/CommonMixin');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var MonthCalendar = function (_React$Component) {
  (0, _inherits3['default'])(MonthCalendar, _React$Component);

  function MonthCalendar(props) {
    (0, _classCallCheck3['default'])(this, MonthCalendar);

    var _this = (0, _possibleConstructorReturn3['default'])(this, _React$Component.call(this, props));

    _this.onKeyDown = function (event) {
      var keyCode = event.keyCode;
      var ctrlKey = event.ctrlKey || event.metaKey;
      var stateValue = _this.state.value;
      var disabledDate = _this.props.disabledDate;

      var value = stateValue;
      switch (keyCode) {
        case _KeyCode2['default'].DOWN:
          value = stateValue.clone();
          value.add(3, 'months');
          break;
        case _KeyCode2['default'].UP:
          value = stateValue.clone();
          value.add(-3, 'months');
          break;
        case _KeyCode2['default'].LEFT:
          value = stateValue.clone();
          if (ctrlKey) {
            value.add(-1, 'years');
          } else {
            value.add(-1, 'months');
          }
          break;
        case _KeyCode2['default'].RIGHT:
          value = stateValue.clone();
          if (ctrlKey) {
            value.add(1, 'years');
          } else {
            value.add(1, 'months');
          }
          break;
        case _KeyCode2['default'].ENTER:
          if (!disabledDate || !disabledDate(stateValue)) {
            _this.onSelect(stateValue);
          }
          event.preventDefault();
          return 1;
        default:
          return undefined;
      }
      if (value !== stateValue) {
        _this.setValue(value);
        event.preventDefault();
        return 1;
      }
    };

    _this.handlePanelChange = function (_, mode) {
      if (mode !== 'date') {
        _this.setState({ mode: mode });
      }
    };

    _this.state = {
      mode: 'month',
      value: props.value || props.defaultValue || (0, _moment2['default'])(),
      selectedValue: props.selectedValue || props.defaultSelectedValue
    };
    return _this;
  }

  MonthCalendar.prototype.render = function render() {
    var props = this.props,
        state = this.state;
    var mode = state.mode,
        value = state.value;

    var children = _react2['default'].createElement(
      'div',
      { className: props.prefixCls + '-month-calendar-content' },
      _react2['default'].createElement(
        'div',
        { className: props.prefixCls + '-month-header-wrap' },
        _react2['default'].createElement(_CalendarHeader2['default'], {
          prefixCls: props.prefixCls,
          mode: mode,
          value: value,
          locale: props.locale,
          disabledMonth: props.disabledDate,
          monthCellRender: props.monthCellRender,
          monthCellContentRender: props.monthCellContentRender,
          onMonthSelect: this.onSelect,
          onValueChange: this.setValue,
          onPanelChange: this.handlePanelChange
        })
      ),
      _react2['default'].createElement(_CalendarFooter2['default'], {
        prefixCls: props.prefixCls,
        renderFooter: props.renderFooter
      })
    );
    return this.renderRoot({
      className: props.prefixCls + '-month-calendar',
      children: children
    });
  };

  return MonthCalendar;
}(_react2['default'].Component);

MonthCalendar.propTypes = (0, _extends3['default'])({}, _CalendarMixin.calendarMixinPropTypes, _CommonMixin.propType, {
  monthCellRender: _propTypes2['default'].func,
  value: _propTypes2['default'].object,
  defaultValue: _propTypes2['default'].object,
  selectedValue: _propTypes2['default'].object,
  defaultSelectedValue: _propTypes2['default'].object,
  disabledDate: _propTypes2['default'].func
});
MonthCalendar.defaultProps = (0, _extends3['default'])({}, _CommonMixin.defaultProp, _CalendarMixin.calendarMixinDefaultProps);
exports['default'] = (0, _reactLifecyclesCompat.polyfill)((0, _CalendarMixin.calendarMixinWrapper)((0, _CommonMixin.commonMixinWrapper)(MonthCalendar)));
module.exports = exports['default'];