'use strict';

exports.__esModule = true;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

var _reactLifecyclesCompat = require('react-lifecycles-compat');

var _KeyCode = require('rc-util/lib/KeyCode');

var _KeyCode2 = _interopRequireDefault(_KeyCode);

var _CalendarPart = require('./range-calendar/CalendarPart');

var _CalendarPart2 = _interopRequireDefault(_CalendarPart);

var _TodayButton = require('./calendar/TodayButton');

var _TodayButton2 = _interopRequireDefault(_TodayButton);

var _OkButton = require('./calendar/OkButton');

var _OkButton2 = _interopRequireDefault(_OkButton);

var _TimePickerButton = require('./calendar/TimePickerButton');

var _TimePickerButton2 = _interopRequireDefault(_TimePickerButton);

var _CommonMixin = require('./mixin/CommonMixin');

var _util = require('./util');

var _toTime = require('./util/toTime');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function noop() {}

function isEmptyArray(arr) {
  return Array.isArray(arr) && (arr.length === 0 || arr.every(function (i) {
    return !i;
  }));
}

function isArraysEqual(a, b) {
  if (a === b) return true;
  if (a === null || typeof a === 'undefined' || b === null || typeof b === 'undefined') {
    return false;
  }
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function getValueFromSelectedValue(selectedValue) {
  var start = selectedValue[0],
      end = selectedValue[1];

  if (end && (start === undefined || start === null)) {
    start = end.clone().subtract(1, 'month');
  }

  if (start && (end === undefined || end === null)) {
    end = start.clone().add(1, 'month');
  }
  return [start, end];
}

function normalizeAnchor(props, init) {
  var selectedValue = props.selectedValue || init && props.defaultSelectedValue;
  var value = props.value || init && props.defaultValue;
  var normalizedValue = value ? getValueFromSelectedValue(value) : getValueFromSelectedValue(selectedValue);
  return !isEmptyArray(normalizedValue) ? normalizedValue : init && [(0, _moment2['default'])(), (0, _moment2['default'])().add(1, 'months')];
}

function generateOptions(length, extraOptionGen) {
  var arr = extraOptionGen ? extraOptionGen().concat() : [];
  for (var value = 0; value < length; value++) {
    if (arr.indexOf(value) === -1) {
      arr.push(value);
    }
  }
  return arr;
}

function onInputSelect(direction, value, cause) {
  if (!value) {
    return;
  }
  var originalValue = this.state.selectedValue;
  var selectedValue = originalValue.concat();
  var index = direction === 'left' ? 0 : 1;
  selectedValue[index] = value;
  if (selectedValue[0] && this.compare(selectedValue[0], selectedValue[1]) > 0) {
    selectedValue[1 - index] = this.state.showTimePicker ? selectedValue[index] : undefined;
  }
  this.props.onInputSelect(selectedValue);
  this.fireSelectValueChange(selectedValue, null, cause || { source: 'dateInput' });
}

var RangeCalendar = function (_React$Component) {
  (0, _inherits3['default'])(RangeCalendar, _React$Component);

  function RangeCalendar(props) {
    (0, _classCallCheck3['default'])(this, RangeCalendar);

    var _this = (0, _possibleConstructorReturn3['default'])(this, _React$Component.call(this, props));

    _initialiseProps.call(_this);

    var selectedValue = props.selectedValue || props.defaultSelectedValue;
    var value = normalizeAnchor(props, 1);
    _this.state = {
      selectedValue: selectedValue,
      prevSelectedValue: selectedValue,
      firstSelectedValue: null,
      hoverValue: props.hoverValue || [],
      value: value,
      showTimePicker: false,
      mode: props.mode || ['date', 'date'],
      panelTriggerSource: '' // Trigger by which picker panel: 'start' & 'end'
    };
    return _this;
  }

  RangeCalendar.getDerivedStateFromProps = function getDerivedStateFromProps(nextProps, state) {
    var newState = {};
    if ('value' in nextProps) {
      newState.value = normalizeAnchor(nextProps, 0);
    }
    if ('hoverValue' in nextProps && !isArraysEqual(state.hoverValue, nextProps.hoverValue)) {
      newState.hoverValue = nextProps.hoverValue;
    }
    if ('selectedValue' in nextProps) {
      newState.selectedValue = nextProps.selectedValue;
      newState.prevSelectedValue = nextProps.selectedValue;
    }
    if ('mode' in nextProps && !isArraysEqual(state.mode, nextProps.mode)) {
      newState.mode = nextProps.mode;
    }
    return newState;
  };

  // get disabled hours for second picker


  RangeCalendar.prototype.render = function render() {
    var _className, _classnames;

    var props = this.props,
        state = this.state;
    var prefixCls = props.prefixCls,
        dateInputPlaceholder = props.dateInputPlaceholder,
        seperator = props.seperator,
        timePicker = props.timePicker,
        showOk = props.showOk,
        locale = props.locale,
        showClear = props.showClear,
        showToday = props.showToday,
        type = props.type,
        clearIcon = props.clearIcon;
    var hoverValue = state.hoverValue,
        selectedValue = state.selectedValue,
        mode = state.mode,
        showTimePicker = state.showTimePicker;

    var className = (_className = {}, _className[props.className] = !!props.className, _className[prefixCls] = 1, _className[prefixCls + '-hidden'] = !props.visible, _className[prefixCls + '-range'] = 1, _className[prefixCls + '-show-time-picker'] = showTimePicker, _className[prefixCls + '-week-number'] = props.showWeekNumber, _className);
    var classes = (0, _classnames3['default'])(className);
    var newProps = {
      selectedValue: state.selectedValue,
      onSelect: this.onSelect,
      onDayHover: type === 'start' && selectedValue[1] || type === 'end' && selectedValue[0] || !!hoverValue.length ? this.onDayHover : undefined
    };

    var placeholder1 = void 0;
    var placeholder2 = void 0;

    if (dateInputPlaceholder) {
      if (Array.isArray(dateInputPlaceholder)) {
        placeholder1 = dateInputPlaceholder[0];
        placeholder2 = dateInputPlaceholder[1];
      } else {
        placeholder1 = placeholder2 = dateInputPlaceholder;
      }
    }
    var showOkButton = showOk === true || showOk !== false && !!timePicker;
    var cls = (0, _classnames3['default'])((_classnames = {}, _classnames[prefixCls + '-footer'] = true, _classnames[prefixCls + '-range-bottom'] = true, _classnames[prefixCls + '-footer-show-ok'] = showOkButton, _classnames));

    var startValue = this.getStartValue();
    var endValue = this.getEndValue();
    var todayTime = (0, _util.getTodayTime)(startValue);
    var thisMonth = todayTime.month();
    var thisYear = todayTime.year();
    var isTodayInView = startValue.year() === thisYear && startValue.month() === thisMonth || endValue.year() === thisYear && endValue.month() === thisMonth;
    var nextMonthOfStart = startValue.clone().add(1, 'months');
    var isClosestMonths = nextMonthOfStart.year() === endValue.year() && nextMonthOfStart.month() === endValue.month();

    var extraFooter = props.renderFooter();

    return _react2['default'].createElement(
      'div',
      {
        ref: this.saveRoot,
        className: classes,
        style: props.style,
        tabIndex: '0',
        onKeyDown: this.onKeyDown
      },
      props.renderSidebar(),
      _react2['default'].createElement(
        'div',
        { className: prefixCls + '-panel' },
        showClear && selectedValue[0] && selectedValue[1] ? _react2['default'].createElement(
          'a',
          {
            role: 'button',
            title: locale.clear,
            onClick: this.clear
          },
          clearIcon || _react2['default'].createElement('span', { className: prefixCls + '-clear-btn' })
        ) : null,
        _react2['default'].createElement(
          'div',
          {
            className: prefixCls + '-date-panel',
            onMouseLeave: type !== 'both' ? this.onDatePanelLeave : undefined,
            onMouseEnter: type !== 'both' ? this.onDatePanelEnter : undefined
          },
          _react2['default'].createElement(_CalendarPart2['default'], (0, _extends3['default'])({}, props, newProps, {
            hoverValue: hoverValue,
            direction: 'left',
            disabledTime: this.disabledStartTime,
            disabledMonth: this.disabledStartMonth,
            format: this.getFormat(),
            value: startValue,
            mode: mode[0],
            placeholder: placeholder1,
            onInputChange: this.onStartInputChange,
            onInputSelect: this.onStartInputSelect,
            onValueChange: this.onStartValueChange,
            onPanelChange: this.onStartPanelChange,
            showDateInput: this.props.showDateInput,
            timePicker: timePicker,
            showTimePicker: showTimePicker || mode[0] === 'time',
            enablePrev: true,
            enableNext: !isClosestMonths || this.isMonthYearPanelShow(mode[1]),
            clearIcon: clearIcon
          })),
          _react2['default'].createElement(
            'span',
            { className: prefixCls + '-range-middle' },
            seperator
          ),
          _react2['default'].createElement(_CalendarPart2['default'], (0, _extends3['default'])({}, props, newProps, {
            hoverValue: hoverValue,
            direction: 'right',
            format: this.getFormat(),
            timePickerDisabledTime: this.getEndDisableTime(),
            placeholder: placeholder2,
            value: endValue,
            mode: mode[1],
            onInputChange: this.onEndInputChange,
            onInputSelect: this.onEndInputSelect,
            onValueChange: this.onEndValueChange,
            onPanelChange: this.onEndPanelChange,
            showDateInput: this.props.showDateInput,
            timePicker: timePicker,
            showTimePicker: showTimePicker || mode[1] === 'time',
            disabledTime: this.disabledEndTime,
            disabledMonth: this.disabledEndMonth,
            enablePrev: !isClosestMonths || this.isMonthYearPanelShow(mode[0]),
            enableNext: true,
            clearIcon: clearIcon
          }))
        ),
        _react2['default'].createElement(
          'div',
          { className: cls },
          showToday || props.timePicker || showOkButton || extraFooter ? _react2['default'].createElement(
            'div',
            { className: prefixCls + '-footer-btn' },
            extraFooter,
            showToday ? _react2['default'].createElement(_TodayButton2['default'], (0, _extends3['default'])({}, props, {
              disabled: isTodayInView,
              value: state.value[0],
              onToday: this.onToday,
              text: locale.backToToday
            })) : null,
            props.timePicker ? _react2['default'].createElement(_TimePickerButton2['default'], (0, _extends3['default'])({}, props, {
              showTimePicker: showTimePicker || mode[0] === 'time' && mode[1] === 'time',
              onOpenTimePicker: this.onOpenTimePicker,
              onCloseTimePicker: this.onCloseTimePicker,
              timePickerDisabled: !this.hasSelectedValue() || hoverValue.length
            })) : null,
            showOkButton ? _react2['default'].createElement(_OkButton2['default'], (0, _extends3['default'])({}, props, {
              onOk: this.onOk,
              okDisabled: !this.isAllowedDateAndTime(selectedValue) || !this.hasSelectedValue() || hoverValue.length
            })) : null
          ) : null
        )
      )
    );
  };

  return RangeCalendar;
}(_react2['default'].Component);

RangeCalendar.propTypes = (0, _extends3['default'])({}, _CommonMixin.propType, {
  prefixCls: _propTypes2['default'].string,
  dateInputPlaceholder: _propTypes2['default'].any,
  seperator: _propTypes2['default'].string,
  defaultValue: _propTypes2['default'].any,
  value: _propTypes2['default'].any,
  hoverValue: _propTypes2['default'].any,
  mode: _propTypes2['default'].arrayOf(_propTypes2['default'].oneOf(['time', 'date', 'month', 'year', 'decade'])),
  showDateInput: _propTypes2['default'].bool,
  timePicker: _propTypes2['default'].any,
  showOk: _propTypes2['default'].bool,
  showToday: _propTypes2['default'].bool,
  defaultSelectedValue: _propTypes2['default'].array,
  selectedValue: _propTypes2['default'].array,
  onOk: _propTypes2['default'].func,
  showClear: _propTypes2['default'].bool,
  locale: _propTypes2['default'].object,
  onChange: _propTypes2['default'].func,
  onSelect: _propTypes2['default'].func,
  onValueChange: _propTypes2['default'].func,
  onHoverChange: _propTypes2['default'].func,
  onPanelChange: _propTypes2['default'].func,
  format: _propTypes2['default'].oneOfType([_propTypes2['default'].string, _propTypes2['default'].arrayOf(_propTypes2['default'].string)]),
  onClear: _propTypes2['default'].func,
  type: _propTypes2['default'].any,
  disabledDate: _propTypes2['default'].func,
  disabledTime: _propTypes2['default'].func,
  clearIcon: _propTypes2['default'].node,
  onKeyDown: _propTypes2['default'].func
});
RangeCalendar.defaultProps = (0, _extends3['default'])({}, _CommonMixin.defaultProp, {
  type: 'both',
  seperator: '~',
  defaultSelectedValue: [],
  onValueChange: noop,
  onHoverChange: noop,
  onPanelChange: noop,
  disabledTime: noop,
  onInputSelect: noop,
  showToday: true,
  showDateInput: true
});

var _initialiseProps = function _initialiseProps() {
  var _this2 = this;

  this.onDatePanelEnter = function () {
    if (_this2.hasSelectedValue()) {
      _this2.fireHoverValueChange(_this2.state.selectedValue.concat());
    }
  };

  this.onDatePanelLeave = function () {
    if (_this2.hasSelectedValue()) {
      _this2.fireHoverValueChange([]);
    }
  };

  this.onSelect = function (value) {
    var type = _this2.props.type;
    var _state = _this2.state,
        selectedValue = _state.selectedValue,
        prevSelectedValue = _state.prevSelectedValue,
        firstSelectedValue = _state.firstSelectedValue;

    var nextSelectedValue = void 0;
    if (type === 'both') {
      if (!firstSelectedValue) {
        (0, _util.syncTime)(prevSelectedValue[0], value);
        nextSelectedValue = [value];
      } else if (_this2.compare(firstSelectedValue, value) < 0) {
        (0, _util.syncTime)(prevSelectedValue[1], value);
        nextSelectedValue = [firstSelectedValue, value];
      } else {
        (0, _util.syncTime)(prevSelectedValue[0], value);
        (0, _util.syncTime)(prevSelectedValue[1], firstSelectedValue);
        nextSelectedValue = [value, firstSelectedValue];
      }
    } else if (type === 'start') {
      (0, _util.syncTime)(prevSelectedValue[0], value);
      var endValue = selectedValue[1];
      nextSelectedValue = endValue && _this2.compare(endValue, value) > 0 ? [value, endValue] : [value];
    } else {
      // type === 'end'
      var startValue = selectedValue[0];
      if (startValue && _this2.compare(startValue, value) <= 0) {
        (0, _util.syncTime)(prevSelectedValue[1], value);
        nextSelectedValue = [startValue, value];
      } else {
        (0, _util.syncTime)(prevSelectedValue[0], value);
        nextSelectedValue = [value];
      }
    }

    _this2.fireSelectValueChange(nextSelectedValue);
  };

  this.onKeyDown = function (event) {
    if (event.target.nodeName.toLowerCase() === 'input') {
      return;
    }

    var keyCode = event.keyCode;

    var ctrlKey = event.ctrlKey || event.metaKey;

    var _state2 = _this2.state,
        selectedValue = _state2.selectedValue,
        hoverValue = _state2.hoverValue,
        firstSelectedValue = _state2.firstSelectedValue,
        value = _state2.value;
    var _props = _this2.props,
        onKeyDown = _props.onKeyDown,
        disabledDate = _props.disabledDate;

    // Update last time of the picker

    var updateHoverPoint = function updateHoverPoint(func) {
      // Change hover to make focus in UI
      var currentHoverTime = void 0;
      var nextHoverTime = void 0;
      var nextHoverValue = void 0;

      if (!firstSelectedValue) {
        currentHoverTime = hoverValue[0] || selectedValue[0] || value[0] || (0, _moment2['default'])();
        nextHoverTime = func(currentHoverTime);
        nextHoverValue = [nextHoverTime];
        _this2.fireHoverValueChange(nextHoverValue);
      } else {
        if (hoverValue.length === 1) {
          currentHoverTime = hoverValue[0].clone();
          nextHoverTime = func(currentHoverTime);
          nextHoverValue = _this2.onDayHover(nextHoverTime);
        } else {
          currentHoverTime = hoverValue[0].isSame(firstSelectedValue, 'day') ? hoverValue[1] : hoverValue[0];
          nextHoverTime = func(currentHoverTime);
          nextHoverValue = _this2.onDayHover(nextHoverTime);
        }
      }

      // Find origin hover time on value index
      if (nextHoverValue.length >= 2) {
        var miss = nextHoverValue.some(function (ht) {
          return !(0, _toTime.includesTime)(value, ht, 'month');
        });
        if (miss) {
          var newValue = nextHoverValue.slice().sort(function (t1, t2) {
            return t1.valueOf() - t2.valueOf();
          });
          if (newValue[0].isSame(newValue[1], 'month')) {
            newValue[1] = newValue[0].clone().add(1, 'month');
          }
          _this2.fireValueChange(newValue);
        }
      } else if (nextHoverValue.length === 1) {
        // If only one value, let's keep the origin panel
        var oriValueIndex = value.findIndex(function (time) {
          return time.isSame(currentHoverTime, 'month');
        });
        if (oriValueIndex === -1) oriValueIndex = 0;

        if (value.every(function (time) {
          return !time.isSame(nextHoverTime, 'month');
        })) {
          var _newValue = value.slice();
          _newValue[oriValueIndex] = nextHoverTime.clone();
          _this2.fireValueChange(_newValue);
        }
      }

      event.preventDefault();

      return nextHoverTime;
    };

    switch (keyCode) {
      case _KeyCode2['default'].DOWN:
        updateHoverPoint(function (time) {
          return (0, _toTime.goTime)(time, 1, 'weeks');
        });
        return;
      case _KeyCode2['default'].UP:
        updateHoverPoint(function (time) {
          return (0, _toTime.goTime)(time, -1, 'weeks');
        });
        return;
      case _KeyCode2['default'].LEFT:
        if (ctrlKey) {
          updateHoverPoint(function (time) {
            return (0, _toTime.goTime)(time, -1, 'years');
          });
        } else {
          updateHoverPoint(function (time) {
            return (0, _toTime.goTime)(time, -1, 'days');
          });
        }
        return;
      case _KeyCode2['default'].RIGHT:
        if (ctrlKey) {
          updateHoverPoint(function (time) {
            return (0, _toTime.goTime)(time, 1, 'years');
          });
        } else {
          updateHoverPoint(function (time) {
            return (0, _toTime.goTime)(time, 1, 'days');
          });
        }
        return;
      case _KeyCode2['default'].HOME:
        updateHoverPoint(function (time) {
          return (0, _toTime.goStartMonth)(time);
        });
        return;
      case _KeyCode2['default'].END:
        updateHoverPoint(function (time) {
          return (0, _toTime.goEndMonth)(time);
        });
        return;
      case _KeyCode2['default'].PAGE_DOWN:
        updateHoverPoint(function (time) {
          return (0, _toTime.goTime)(time, 1, 'month');
        });
        return;
      case _KeyCode2['default'].PAGE_UP:
        updateHoverPoint(function (time) {
          return (0, _toTime.goTime)(time, -1, 'month');
        });
        return;
      case _KeyCode2['default'].ENTER:
        {
          var lastValue = void 0;
          if (hoverValue.length === 0) {
            lastValue = updateHoverPoint(function (time) {
              return time;
            });
          } else if (hoverValue.length === 1) {
            lastValue = hoverValue[0];
          } else {
            lastValue = hoverValue[0].isSame(firstSelectedValue, 'day') ? hoverValue[1] : hoverValue[0];
          }
          if (lastValue && (!disabledDate || !disabledDate(lastValue))) {
            _this2.onSelect(lastValue);
          }
          event.preventDefault();
          return;
        }
      default:
        if (onKeyDown) {
          onKeyDown(event);
        }
    }
  };

  this.onDayHover = function (value) {
    var hoverValue = [];
    var _state3 = _this2.state,
        selectedValue = _state3.selectedValue,
        firstSelectedValue = _state3.firstSelectedValue;
    var type = _this2.props.type;

    if (type === 'start' && selectedValue[1]) {
      hoverValue = _this2.compare(value, selectedValue[1]) < 0 ? [value, selectedValue[1]] : [value];
    } else if (type === 'end' && selectedValue[0]) {
      hoverValue = _this2.compare(value, selectedValue[0]) > 0 ? [selectedValue[0], value] : [];
    } else {
      if (!firstSelectedValue) {
        if (_this2.state.hoverValue.length) {
          _this2.setState({ hoverValue: [] });
        }
        return hoverValue;
      }
      hoverValue = _this2.compare(value, firstSelectedValue) < 0 ? [value, firstSelectedValue] : [firstSelectedValue, value];
    }
    _this2.fireHoverValueChange(hoverValue);

    return hoverValue;
  };

  this.onToday = function () {
    var startValue = (0, _util.getTodayTime)(_this2.state.value[0]);
    var endValue = startValue.clone().add(1, 'months');
    _this2.setState({ value: [startValue, endValue] });
  };

  this.onOpenTimePicker = function () {
    _this2.setState({
      showTimePicker: true
    });
  };

  this.onCloseTimePicker = function () {
    _this2.setState({
      showTimePicker: false
    });
  };

  this.onOk = function () {
    var selectedValue = _this2.state.selectedValue;

    if (_this2.isAllowedDateAndTime(selectedValue)) {
      _this2.props.onOk(_this2.state.selectedValue);
    }
  };

  this.onStartInputChange = function () {
    for (var _len = arguments.length, oargs = Array(_len), _key = 0; _key < _len; _key++) {
      oargs[_key] = arguments[_key];
    }

    var args = ['left'].concat(oargs);
    return onInputSelect.apply(_this2, args);
  };

  this.onEndInputChange = function () {
    for (var _len2 = arguments.length, oargs = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      oargs[_key2] = arguments[_key2];
    }

    var args = ['right'].concat(oargs);
    return onInputSelect.apply(_this2, args);
  };

  this.onStartInputSelect = function (value) {
    var args = ['left', value, { source: 'dateInputSelect' }];
    return onInputSelect.apply(_this2, args);
  };

  this.onEndInputSelect = function (value) {
    var args = ['right', value, { source: 'dateInputSelect' }];
    return onInputSelect.apply(_this2, args);
  };

  this.onStartValueChange = function (leftValue) {
    var value = [].concat(_this2.state.value);
    value[0] = leftValue;
    return _this2.fireValueChange(value);
  };

  this.onEndValueChange = function (rightValue) {
    var value = [].concat(_this2.state.value);
    value[1] = rightValue;
    return _this2.fireValueChange(value);
  };

  this.onStartPanelChange = function (value, mode) {
    var props = _this2.props,
        state = _this2.state;

    var newMode = [mode, state.mode[1]];
    var newState = {
      panelTriggerSource: 'start'
    };
    if (!('mode' in props)) {
      newState.mode = newMode;
    }
    _this2.setState(newState);
    var newValue = [value || state.value[0], state.value[1]];
    props.onPanelChange(newValue, newMode);
  };

  this.onEndPanelChange = function (value, mode) {
    var props = _this2.props,
        state = _this2.state;

    var newMode = [state.mode[0], mode];
    var newState = {
      panelTriggerSource: 'end'
    };
    if (!('mode' in props)) {
      newState.mode = newMode;
    }
    _this2.setState(newState);
    var newValue = [state.value[0], value || state.value[1]];
    props.onPanelChange(newValue, newMode);
  };

  this.getStartValue = function () {
    var _state4 = _this2.state,
        selectedValue = _state4.selectedValue,
        showTimePicker = _state4.showTimePicker,
        value = _state4.value,
        mode = _state4.mode,
        panelTriggerSource = _state4.panelTriggerSource;

    var startValue = value[0];
    // keep selectedTime when select date
    if (selectedValue[0] && _this2.props.timePicker) {
      startValue = startValue.clone();
      (0, _util.syncTime)(selectedValue[0], startValue);
    }
    if (showTimePicker && selectedValue[0]) {
      startValue = selectedValue[0];
    }

    // Adjust month if date not align
    if (panelTriggerSource === 'end' && mode[0] === 'date' && mode[1] === 'date' && startValue.isSame(value[1], 'month')) {
      startValue = startValue.clone().subtract(1, 'month');
    }

    return startValue;
  };

  this.getEndValue = function () {
    var _state5 = _this2.state,
        value = _state5.value,
        selectedValue = _state5.selectedValue,
        showTimePicker = _state5.showTimePicker,
        mode = _state5.mode,
        panelTriggerSource = _state5.panelTriggerSource;

    var endValue = value[1] ? value[1].clone() : value[0].clone().add(1, 'month');
    // keep selectedTime when select date
    if (selectedValue[1] && _this2.props.timePicker) {
      (0, _util.syncTime)(selectedValue[1], endValue);
    }
    if (showTimePicker) {
      endValue = selectedValue[1] ? selectedValue[1] : _this2.getStartValue();
    }

    // Adjust month if date not align
    if (!showTimePicker && panelTriggerSource !== 'end' && mode[0] === 'date' && mode[1] === 'date' && endValue.isSame(value[0], 'month')) {
      endValue = endValue.clone().add(1, 'month');
    }

    return endValue;
  };

  this.getEndDisableTime = function () {
    var _state6 = _this2.state,
        selectedValue = _state6.selectedValue,
        value = _state6.value;
    var disabledTime = _this2.props.disabledTime;

    var userSettingDisabledTime = disabledTime(selectedValue, 'end') || {};
    var startValue = selectedValue && selectedValue[0] || value[0].clone();
    // if startTime and endTime is same day..
    // the second time picker will not able to pick time before first time picker
    if (!selectedValue[1] || startValue.isSame(selectedValue[1], 'day')) {
      var hours = startValue.hour();
      var minutes = startValue.minute();
      var second = startValue.second();
      var _disabledHours = userSettingDisabledTime.disabledHours,
          _disabledMinutes = userSettingDisabledTime.disabledMinutes,
          _disabledSeconds = userSettingDisabledTime.disabledSeconds;

      var oldDisabledMinutes = _disabledMinutes ? _disabledMinutes() : [];
      var olddisabledSeconds = _disabledSeconds ? _disabledSeconds() : [];
      _disabledHours = generateOptions(hours, _disabledHours);
      _disabledMinutes = generateOptions(minutes, _disabledMinutes);
      _disabledSeconds = generateOptions(second, _disabledSeconds);
      return {
        disabledHours: function disabledHours() {
          return _disabledHours;
        },
        disabledMinutes: function disabledMinutes(hour) {
          if (hour === hours) {
            return _disabledMinutes;
          }
          return oldDisabledMinutes;
        },
        disabledSeconds: function disabledSeconds(hour, minute) {
          if (hour === hours && minute === minutes) {
            return _disabledSeconds;
          }
          return olddisabledSeconds;
        }
      };
    }
    return userSettingDisabledTime;
  };

  this.isAllowedDateAndTime = function (selectedValue) {
    return (0, _util.isAllowedDate)(selectedValue[0], _this2.props.disabledDate, _this2.disabledStartTime) && (0, _util.isAllowedDate)(selectedValue[1], _this2.props.disabledDate, _this2.disabledEndTime);
  };

  this.isMonthYearPanelShow = function (mode) {
    return ['month', 'year', 'decade'].indexOf(mode) > -1;
  };

  this.hasSelectedValue = function () {
    var selectedValue = _this2.state.selectedValue;

    return !!selectedValue[1] && !!selectedValue[0];
  };

  this.compare = function (v1, v2) {
    if (_this2.props.timePicker) {
      return v1.diff(v2);
    }
    return v1.diff(v2, 'days');
  };

  this.fireSelectValueChange = function (selectedValue, direct, cause) {
    var timePicker = _this2.props.timePicker;
    var prevSelectedValue = _this2.state.prevSelectedValue;

    if (timePicker && timePicker.props.defaultValue) {
      var timePickerDefaultValue = timePicker.props.defaultValue;
      if (!prevSelectedValue[0] && selectedValue[0]) {
        (0, _util.syncTime)(timePickerDefaultValue[0], selectedValue[0]);
      }
      if (!prevSelectedValue[1] && selectedValue[1]) {
        (0, _util.syncTime)(timePickerDefaultValue[1], selectedValue[1]);
      }
    }

    if (!('selectedValue' in _this2.props)) {
      _this2.setState({
        selectedValue: selectedValue
      });
    }

    // 尚未选择过时间，直接输入的话
    if (!_this2.state.selectedValue[0] || !_this2.state.selectedValue[1]) {
      var startValue = selectedValue[0] || (0, _moment2['default'])();
      var endValue = selectedValue[1] || startValue.clone().add(1, 'months');
      _this2.setState({
        selectedValue: selectedValue,
        value: getValueFromSelectedValue([startValue, endValue])
      });
    }

    if (selectedValue[0] && !selectedValue[1]) {
      _this2.setState({ firstSelectedValue: selectedValue[0] });
      _this2.fireHoverValueChange(selectedValue.concat());
    }
    _this2.props.onChange(selectedValue);
    if (direct || selectedValue[0] && selectedValue[1]) {
      _this2.setState({
        prevSelectedValue: selectedValue,
        firstSelectedValue: null
      });
      _this2.fireHoverValueChange([]);
      _this2.props.onSelect(selectedValue, cause);
    }
  };

  this.fireValueChange = function (value) {
    var props = _this2.props;
    if (!('value' in props)) {
      _this2.setState({
        value: value
      });
    }
    props.onValueChange(value);
  };

  this.fireHoverValueChange = function (hoverValue) {
    var props = _this2.props;
    if (!('hoverValue' in props)) {
      _this2.setState({ hoverValue: hoverValue });
    }
    props.onHoverChange(hoverValue);
  };

  this.clear = function () {
    _this2.fireSelectValueChange([], true);
    _this2.props.onClear();
  };

  this.disabledStartTime = function (time) {
    return _this2.props.disabledTime(time, 'start');
  };

  this.disabledEndTime = function (time) {
    return _this2.props.disabledTime(time, 'end');
  };

  this.disabledStartMonth = function (month) {
    var value = _this2.state.value;

    return month.isAfter(value[1], 'month');
  };

  this.disabledEndMonth = function (month) {
    var value = _this2.state.value;

    return month.isBefore(value[0], 'month');
  };
};

(0, _reactLifecyclesCompat.polyfill)(RangeCalendar);

exports['default'] = (0, _CommonMixin.commonMixinWrapper)(RangeCalendar);
module.exports = exports['default'];